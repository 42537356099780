import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Header from '../components/Common/Header';
import Sidebar from '../components/Common/Sidebar';
import placeholder_img from '../images/camera_img.svg';
import { toast } from 'react-toastify';

const data = localStorage.getItem('vinnData');
const parsed_data = JSON.parse(data);
function Bulletin() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const bulletinId = searchParams.get('id');

  const [openSidebar, setOpenSidebar] = useState(false);
  const data = localStorage.getItem('vinnData');
  const bulletindata = localStorage.getItem('bulletinData');
  const bulletinnewdata = JSON.parse(bulletindata);
  const parsed_bulletin_data = JSON.parse(bulletindata);
  const db_bulletin_data = parsed_bulletin_data?.data;
  console.log('ccvxvcx', bulletinnewdata);
  const firstBulletinid = bulletinnewdata[0]?.id;

  console.log('ccvxvcx4444444444', bulletinnewdata[0]?.pdf_instructions);
  const profile_id = localStorage.getItem('profile_id');
  const parsed_data = JSON.parse(data);
  const db_data = parsed_data?.data;
  const product_id = db_data?.id;
  const dateStr = db_data?.date_of_manufacture;
  const dateObj = new Date(dateStr);
  const year = dateObj.getFullYear();
  // console.log("db_data+++++>> ",db_data)
  const image_url = `${db_data?.image}`;

  const [isFormFilled, setIsFormFilled] = useState(false);

  // const [openSidebar, setOpenSidebar] = useState(false)
  const [comment, setComment] = useState('');
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [selectedFile4, setSelectedFile4] = useState(null);
  const [files, setFiles] = useState([null, null, null, null]);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [selectedFile5, setSelectedFile5] = useState(null);
  const timers = useRef([]);
  const [services, setServices] = useState([]);
  const [fillvalue, setfillValue] = useState({});
  const [valvalue, setvalValue] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentItem = services[currentIndex];
  ///////
  const [isYesChecked, setIsYesChecked] = useState({});
  const [isChecked, setIsChecked] = useState({});
  const [textValue, setTextValue] = useState({});
  const [selectedFile6, setSelectedFile6] = useState(null);
  const timerRef = useRef([]);
  const [timer, setTimer] = useState({});
  const [disabledIndices, setDisabledIndices] = useState([]);
  const [timerValue, setTimerValue] = useState(0);
  const [timerRunning, setTimerRunning] = useState(false);
  const [selectedMileage, setSelectedMileage] = useState(null);

  const [dataPost, setDataPost] = useState({
    // maintanence_id: 3,
    bulletins_id: bulletinId || null,
    bulletins_mechanical_note: '',
  });
  const handleImageClick2 = (ref) => {
    console.log('image picker', ref);

    // Check if the device is a mobile device
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile) {
      // Mobile device - Ask the user for their choice
      if (
        window.confirm(
          "Do you want to take a new photo? Click 'OK' for camera, 'Cancel' for gallery."
        )
      ) {
        // User chooses to take a new photo
        ref.current.setAttribute('capture', 'environment');
      } else {
        // User chooses to pick an existing photo
        ref.current.removeAttribute('capture');
      }
    } else {
      // Non-mobile device - Standard file input behavior
      ref.current.removeAttribute('capture');
    }

    // Trigger the file input
    ref.current.click();
  };
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const fileInputRef4 = useRef(null);
  const fileInputRef6 = useRef(null);
  const fileInputRef5 = useRef(null);

  const handleImageClick = (ref) => {
    console.log('image pciker ', ref);
    ref.current.click();
  };

  const handleFileSelect1 = (event) => {
    setSelectedFile1(URL.createObjectURL(event.target.files[0]));
    files[0] = event.target.files[0];
    setFiles([...files]);
  };

  const handleFileSelect2 = (event) => {
    setSelectedFile2(URL.createObjectURL(event.target.files[0]));
    files[1] = event.target.files[0];
    setFiles([...files]);
  };

  const handleFileSelect3 = (event) => {
    setSelectedFile3(URL.createObjectURL(event.target.files[0]));
    files[2] = event.target.files[0];
    setFiles([...files]);
  };

  const handleFileSelect4 = (event) => {
    setSelectedFile4(URL.createObjectURL(event.target.files[0]));
    files[3] = event.target.files[0];
    setFiles([...files]);
  };
  const handleFileSelect6 = (event) => {
    setSelectedFile6(URL.createObjectURL(event.target.files[0]));

    setDataPost({
      ...dataPost,
      video: event.target.files[0],
    });
  };

  const handleFileSelect5 = (event) => {
    setSelectedFile5(URL.createObjectURL(event.target.files[0]));

    setDataPost({
      ...dataPost,
      picture: event.target.files[0],
    });
  };
  console.log('checkingHaDev save services isChecked', isChecked);

  const savebuletin = () => {
    if (comment === '') {
      toast.error('Please provide mechanical comments');
      return;
    }

    // Check if all required images are uploaded
    if (!files[0] || !files[1] || !files[2] || !files[3]) {
      toast.error('Please upload all required images');
      return;
    }

    const data = new FormData();
    data.append('bulletins_id', parseInt(bulletinId));
    data.append('bulletins_mechanical_note', comment);
    data.append('vincode', db_data?.vin_code);

    data.append('speedometer_image', files[0]);
    data.append('vin_image', files[1]);
    data.append('front_45_view_picture', files[2]);
    data.append('rear_45_view_picture', files[3]);
    data.append('mileage', selectedMileage);
    data.append('user', profile_id);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/add-bulletins-completed/`,
        data
      )
      .then((res) => {
        const formdata = new FormData();
        const profile = localStorage.getItem('profile');
        const company_name = localStorage.getItem('company_name');

        formdata.append(
          'description',
          `Bulletin executed by ${profile} from ${company_name}`
        );
        formdata.append(
          'estonian_description',
          `Teadaanne on teostatud ${profile} poolt ${company_name}`
        );
        formdata.append(
          'latvian_description',
          `Bulletin viewed by ${profile} from ${company_name}`
        );
        formdata.append(
          'finnish_description',
          `Bulletin viewed by ${profile} from ${company_name}`
        );
        formdata.append('historical_note', '');
        formdata.append('vehicle', db_data?.id);
        formdata.append('owner_email', db_data?.owner_email);
        formdata.append('plate_number', db_data?.plate_number);
        formdata.append('vin_code', db_data?.vin_code);

        return fetch(`${process.env.REACT_APP_API_URL}/history/create/`, {
          method: 'POST',
          body: formdata,
        });
      })
      .then(() => {
        toast.success('Bulletin saved successfully');
        navigate('/vehicleData');
      })
      .catch((error) => {
        console.error('Error:', error);
        toast.error('Error saving bulletin');
      });
  };
  const allAPICall = async () => {
    const res = await axios.get(
      // `${process.env.REACT_APP_API_URL}//getallservices/`
      `${process.env.REACT_APP_API_URL}/getallservices/`
    );
    console.log('----------', res);
    setServices(res);
  };
  useEffect(() => {
    allAPICall();
  }, []);
  console.log('------->>>>>>>>>>>>>>.--', services);

  const handleConfirmService = async (name, instruction) => {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/services/create/`,
      {
        name: name,
        instructions: instruction,
        instruction_active: true,
      }
    );
    allAPICall();
  };

  console.log('services__services__services__services__', services);
  function downloadPdfFile(pdfUrl) {
    // Create an anchor element
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.target = '_blank';
    link.download = 'your_file_name.pdf'; // Set the desired file name here

    // Trigger a click event on the anchor element
    link.click();
  }

  const selectedBulletin = bulletinnewdata.find(
    (bulletin) => bulletin.id === parseInt(bulletinId)
  );
  const pdf_url = `${selectedBulletin?.pdf_instructions}`;

  const handleBulletinViewed = () => {
    const formdata = new FormData();
    const profile = localStorage.getItem('profile');
    const company_name = localStorage.getItem('company_name');
    formdata.append(
      'description',
      `Bulletin viewed by ${profile} from ${company_name}`
    );
    formdata.append(
      'estonian_description',
      `Teadaanne vaadatud ${profile} poolt ${company_name}`
    );
    formdata.append(
      'latvian_description',
      `Bulletin viewed by ${profile} from ${company_name}`
    );
    formdata.append(
      'finnish_description',
      `Bulletin viewed by ${profile} from ${company_name}`
    );
    formdata.append('historical_note', '');
    formdata.append('vehicle', db_data?.id);
    formdata.append('owner_email', db_data?.owner_email);
    formdata.append('plate_number', db_data?.plate_number);
    formdata.append('vin_code', db_data?.vin_code);

    fetch(`${process.env.REACT_APP_API_URL}/history/create/`, {
      method: 'POST',
      body: formdata,
    })
      .then((response) => response.json())
      .then(() => {
        navigate('/vehicleData');
      })
      .catch((error) => {
        console.error('Error creating history:', error);
      });
  };

  useEffect(() => {
    const checkFormCompletion = () => {
      const hasComment = comment.length > 0;
      const hasPicture = dataPost?.picture !== null;
      const hasVideo = dataPost?.video !== null;

      setIsFormFilled(hasPicture && hasVideo && hasComment);
    };

    checkFormCompletion();
  }, [comment, dataPost?.picture, dataPost?.video]);
  return (
    <>
      <div className='flex'>
        <Sidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />

        <Header setOpenSidebar={setOpenSidebar} />

        <main className='md:ml-[16.25rem] xl:ml-[21.125rem] pt-[62px] md:pt-[6.5625rem] w-full'>
          {/* ADD NEW SERVICE */}
          <div className='px-[26px] md:pl-9 xl:pl-[3.25rem] md:pr-10 pt-[22px] md:pt-[42px] xl:pt-14 pb-11 xl:pb-16'>
            <div className='flex flex-col md:flex-row mb-[22px] md:mb-[30px] xl:mb-14'>
              {/* LEFT */}
              <div className='w-full mb-10 md:mb-0 md:w-1/2 md:pt-3 xl:pt-12'>
                {/* IMAGE */}
                <div>
                  <img className='mx-auto' src={image_url} alt='bike-img' />
                </div>
              </div>

              {/* RIGHT */}
              <div className='w-full md:w-1/2 flex flex-col self-center'>
                <div className='md:pl-5 xl:pl-[30px]'>
                  {/* BIKE NAME */}
                  <div className='mb-3 md:mb-1 xl:mb-[86px]'>
                    <h3 className='bg-gradient-text text-f_19_l_24 md:text-f_22_l_28 xl:text-f_27_l_34 text-left font-supremeBold'>
                      {db_data?.model_name}, {db_data?.year}, {db_data?.color}
                    </h3>
                  </div>

                  {/* SERVICE-KM */}
                  <div className='mb-3 md:mb-[30px] xl:mb-[83px]'>
                    <h1 className='text-blue text-f_16_l_20 md:text-f_22_l_28 xl:text-f_35_l_44 font-supremeBold'>
                      {selectedBulletin?.bulletins_name}
                    </h1>
                  </div>
                  <div>
                    <h4 className='text-f_16_l_20 md:text-f_18_l_23 xl:text-f_22_l_28 font-supremeBold text-white-100 mb-3 md:mb-1.5 xl:mb-4'>
                      {t('bulletin_milage')}
                      <span>
                        <input
                          // disabled={isProgress}
                          value={selectedMileage}
                          onChange={(event) =>
                            setSelectedMileage(event.target.value)
                          }
                          type='number'
                          className='rounded-lg px-3 py-2 bg-gray-900 w-24 h-8 border border-gray-400'
                        />
                      </span>{' '}
                      km
                    </h4>
                  </div>
                </div>
              </div>
            </div>

            {/* VEHICLE PHOTOS */}
            <div className='mb-[67px] md:mb-[41px] xl:mb-[90px]'>
              {/* TITLE */}
              <div className='mb-6 md:mb-14 xl:mb-[43px]'>
                <h3 className='text-f_20_l_25 md:text-f_22_l_28 text-white-100 font-supremeBold mb-3 md:mb-2.5'>
                  {t('Bulletins_description')}
                </h3>

                <p className='text-f_14_l_22 md:text-f_16_l_25 xl:text-f_18_l_28 text-white-100 text-left'>
                  {selectedBulletin?.bulletins_description}
                </p>
              </div>
            </div>

            {/* VEHICLE PHOTOS */}
            <div className='mb-[67px] md:mb-[41px] xl:mb-[90px]'>
              {/* TITLE */}
              <div className='mb-6 md:mb-14 xl:mb-[43px]'>
                <p className='text-f_14_l_22 md:text-f_16_l_25 xl:text-f_18_l_28 text-white-100'>
                  {t('Vehicle_photos_desc')}
                </p>
              </div>

              <div className='grid grid-cols-2 xl:grid-cols-4 gap-5 md:gap-y-[27px] md:gap-x-[30px]'>
                {[
                  {
                    file: selectedFile1,
                    placeholder: 'Addspeedometer',
                    ref: fileInputRef1,
                    onChange: handleFileSelect1,
                  },
                  {
                    file: selectedFile2,
                    placeholder: 'addvincode',
                    ref: fileInputRef2,
                    onChange: handleFileSelect2,
                  },
                  {
                    file: selectedFile3,
                    placeholder: 'add45front',
                    ref: fileInputRef3,
                    onChange: handleFileSelect3,
                  },
                  {
                    file: selectedFile4,
                    placeholder: 'add45back',
                    ref: fileInputRef4,
                    onChange: handleFileSelect4,
                  },
                ].map((image, index) => (
                  <div key={index}>
                    <div
                      className='w-full h-[156px] md:h-[196px] xl:h-[168px] flex justify-center items-center bg-black-500 rounded-[10px]'
                      style={{
                        backgroundImage: image.file
                          ? `url(${image.file})`
                          : 'none',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                      }}
                      onClick={() => handleImageClick(image.ref)}
                    >
                      {!image.file && (
                        <div className='flex flex-col justify-center items-center cursor-pointer text-center'>
                          <img src={placeholder_img} alt='add-img' />
                          <div
                            className='text-f_18_l_28 mt-3.5 break-all hyphens-auto'
                            style={{ wordBreak: 'break-word' }}
                          >
                            {t(image.placeholder)}
                          </div>
                        </div>
                      )}
                    </div>
                    <input
                      type='file'
                      ref={image.ref}
                      style={{ display: 'none' }}
                      accept='image/png, image/jpeg, image/jpg'
                      onChange={image.onChange}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className='mb-[67px] md:mb-[41px] xl:mb-[90px] flex'>
              <div className='w-3/5  flex items-center'>
                {/* 60% width */}
                <h3 className='text-f_20_l_25 md:text-f_22_l_28 text-white-100 font-supremeBold mb-3 md:mb-2.5'>
                  {t('Factory_instructions')}
                </h3>
              </div>
              <div className='w-3/5 pl-4 flex flex-col'>
                {/* 60% width */}
                <div className='w-3/5 pl-4 flex'>
                  {/* 60% width */}
                  <button
                    className='w-full md:w-[55%] h-12 xl:h-[73px] rounded-[10px] border border-white xl:text-f_22_l_28 font-supremeMedium uppercase'
                    onClick={() => downloadPdfFile(pdf_url)}
                    style={{ color: 'red', borderColor: 'red' }}
                  >
                    {t('OPEN_FILE')}
                  </button>
                </div>
              </div>
            </div>

            {/* VEHICLE PHOTOS */}
            <div className='mb-[67px] md:mb-[41px] xl:mb-[90px]'>
              {/* TITLE */}
              <div className='mb-6 md:mb-14 xl:mb-[43px]'>
                <h3 className='text-f_20_l_25 md:text-f_22_l_28 text-white-100 font-supremeBold mb-3 md:mb-2.5'>
                  {t('Upload_photos_and_videos')}
                </h3>
                {/* <p className=" text-f_14_l_22 md:text-f_16_l_25 xl:text-f_18_l_28 text-white-100">
                  Photo of the speedometer (numbers must be legible), VIN code
                  (numbers must be legible), 45° front view ja 45° rear view
                </p> */}
              </div>

              <div className='grid grid-cols-2 xl:grid-cols-4 gap-5 md:gap-y-[27px] md:gap-x-[30px]'>
                {/* IMAGE 1 */}
                <div>
                  {/* IMAGE AREA */}
                  <div
                    className='w-full h-[156px] md:h-[210px] lg:h-[305px] xl:h-[280px] bg-contain bg-no-repeat bg-center flex justify-center items-center bg-black-500 rounded-[10px]'
                    style={{
                      backgroundImage: selectedFile5
                        ? `url(${selectedFile5})`
                        : 'none',
                    }}
                    onClick={(e) => handleImageClick2(fileInputRef5)}
                  >
                    {!selectedFile5 && (
                      <div className='flex flex-col justify-center items-center cursor-pointer'>
                        <img src={placeholder_img} alt='add-img' />
                        <div className=' text-f_18_l_28 mt-3.5'>
                          {t('add_photo')}
                        </div>
                      </div>
                    )}
                  </div>

                  {/* INPUT */}
                  <input
                    type='file'
                    ref={fileInputRef5}
                    style={{ visibility: false, display: 'none' }}
                    accept='image/png, image/jpeg, image/jpg'
                    onChange={handleFileSelect5}
                  />
                </div>
                {/* IMAGE 2 */}
                <div>
                  {/* IMAGE AREA */}
                  <div
                    className='w-full h-[156px] md:h-[210px] lg:h-[305px] xl:h-[280px] bg-contain bg-no-repeat bg-center flex justify-center items-center bg-black-500 rounded-[10px]'
                    style={{
                      backgroundImage: selectedFile6
                        ? `url(${selectedFile6})`
                        : 'none',
                      backgroundSize: 'cover', // Stretch the background image to cover the container
                      backgroundPosition: 'center',
                    }}
                    onClick={(e) => handleImageClick(fileInputRef6)}
                  >
                    {!selectedFile6 ? (
                      <div className='flex flex-col justify-center items-center cursor-pointer'>
                        <img src={placeholder_img} alt='add-img' />
                        <div className=' text-f_18_l_28 mt-3.5'>
                          {t('add_video')}
                        </div>
                      </div>
                    ) : (
                      <>
                        <video
                          src={selectedFile6}
                          style={{
                            height: '100%',
                            objectFit: 'fill',
                          }}
                        ></video>
                      </>
                    )}
                  </div>

                  {/* INPUT */}
                  <input
                    type='file'
                    ref={fileInputRef6}
                    style={{ visibility: false, display: 'none' }}
                    accept='video/mp4, video/quicktime'
                    onChange={handleFileSelect6}
                  />
                </div>
              </div>
            </div>
            {/* SPARE PARTS FOR SERVICE */}
            <div className='mb-7'>
              <h3 className='text-f_22_l_28 md:text-f_27_l_34 font-supremeBold mb-[25px] md:mb-5'>
                {t('bulettin_Mechanic_comment')}
              </h3>

              <textarea
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                className='h-[169px] border border-white-100 rounded-[10px] bg-transparent w-full'
              />
            </div>
            {/* SAVE SERVICE DATA BUTTON */}
            <div className='flex gap-4'>
              <button
                className='p-4 bg-gradient rounded-[10px] text-f_16_l_20 md:text-f_22_l_28 font-supremeMedium'
                onClick={(event) => {
                  savebuletin();
                  // event.target.style.display = 'none';
                }}
              >
                {t('Bulletin_executed')}
              </button>

              {!isFormFilled && (
                <button
                  className='p-4 border border-white rounded-[10px] text-f_16_l_20 md:text-f_22_l_28 font-supremeMedium'
                  onClick={(event) => {
                    handleBulletinViewed();
                    event.target.style.display = 'none';
                  }}
                >
                  {t('Mark_as_viewed')}
                </button>
              )}
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default Bulletin;
