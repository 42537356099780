import React, { useState, useEffect } from 'react';
import AddWork from './Addwork';
import Waranity from '../Pages/waranty';
import Header from './../components/Common/Header';
import Sidebar from './../components/Common/Sidebar';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import AddNewService from './AddNewService';
import Maintainence from './maintainence';
import { useDispatch } from 'react-redux';
import { loaderActions } from '../state/actions';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

const Progress = ({ isProgress }) => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const [activeTab, setActiveTab] = useState('add-work');
  const [worStatusData, setWorkStatusData] = useState(null);
  const [markVerify, setMarkVerify] = useState(false);
  const { t } = useTranslation();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const workIdFromURL = queryParams.get('workId');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  async function logout_function() {
    const userEmail = localStorage.getItem('profile_email');
    const vinnData = JSON.parse(localStorage.getItem('vinnData'));

    if (vinnData && vinnData.data && vinnData.data.vin_code) {
      const vinCode = vinnData.data.vin_code;
      await fetch(
        `${process.env.REACT_APP_API_URL}/vehicles/logout_vehicle/${userEmail}/?Vehicle_servicing=${vinCode}`
      );
    }
  }

  const allTabs = [
    { id: 'add-work', label: t('ADDWORK'), checkKey: null }, // Always visible
    { id: 'maintenance', label: t('MAINTENANCE'), checkKey: 'is_maintenance' },
    { id: 'repairing', label: t('REPAIRING'), checkKey: 'is_repair' },
    { id: 'warranty', label: t('WARRANTY'), checkKey: 'is_warranty' },
  ];

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/addwork/add-work/${workIdFromURL}`
      )
      .then((response) => {
        setWorkStatusData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [workIdFromURL]);

  const filteredTabs = allTabs?.filter(
    (tab) =>
      tab.checkKey === null || (worStatusData && worStatusData[tab.checkKey])
  );

  const handleVerifyAddWord = async () => {
    try {
      dispatch(loaderActions.showLoader());

      const data = JSON.stringify({
        status: 6,
      });

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/addwork/add-work/${workIdFromURL}/`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: data,
        }
      );

      if (response.status === 404) {
        console.error('PATCH request failed with 404. Attempting POST...');
        // Optional: Add POST logic here if required
      } else if (response.status === 200) {
        console.log('PATCH request successful. Logging out...');
        await logout_function(); // Ensure logout_function is async
        navigate('/dashboard');
      } else {
        console.error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error in handleVerifyAddWord:', error);
    } finally {
      dispatch(loaderActions.hideLoader());
    }
  };

  const logout_functions = async () => {
    try {
      localStorage.removeItem('vinnData');
      navigate('/');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const handleVerifyClick = async () => {
    setMarkVerify(true);
    handleVerifyAddWord();
    try {
      await logout_functions();
    } catch (error) {
      console.error('Error during verification and logout:', error);
      setMarkVerify(false);
    }
  };

  return (
    <div className='flex h-screen'>
      <Sidebar
        openSidebar={openSidebar}
        setOpenSidebar={setOpenSidebar}
        activeMe='dashboard'
      />

      <div className='flex-1 flex flex-col'>
        <Header setOpenSidebar={setOpenSidebar} />

        <main className='flex-1 overflow-auto mt-16'>
          {/* Main content container with responsive padding */}
          <div className='flex flex-col items-center pt-6 mt-10 px-1 sm:px-2 md:px-6'>
            {/* Responsive container for tabs and button */}
            <div className='w-full flex flex-col md:flex-row md:items-center gap-2 sm:gap-4 md:gap-0'>
              {/* Spacer div - hidden on mobile */}
              <div className='hidden md:block md:w-2/5 md:ml-12'></div>

              {/* Tabs container - vertical on mobile, horizontal on md screens */}
              <div className='w-full md:w-1/2 flex flex-col md:flex-row justify-start overflow-visible md:overflow-x-auto'>
                <div className='flex flex-col md:flex-row bg-[#1e2530]/60 rounded-lg p-1 w-full md:w-auto'>
                  {filteredTabs?.map((tab) => (
                    <button
                      key={tab?.id}
                      onClick={() => setActiveTab(tab.id)}
                      className={`
                        px-2 py-2 md:px-4 md:py-3 rounded-lg text-xs md:text-sm font-medium transition-colors 
                        mb-1 md:mb-0 md:mr-1 last:mb-0 md:last:mr-0
                        ${
                          activeTab === tab?.id
                            ? 'bg-gray-700 text-white shadow-lg'
                            : 'text-gray-300 hover:text-white hover:bg-gray-700'
                        }
                      `}
                    >
                      {tab?.label}
                    </button>
                  ))}
                </div>
              </div>
              <div className='flex justify-center md:justify-end md:mr-4 mt-4 md:mt-0 w-full md:w-auto'>
                <button
                  onClick={handleVerifyClick}
                  className='px-2 py-1.5 md:px-6 md:py-2 bg-gradient rounded-[10px] text-xs md:text-f_16_l_20 font-supremeMedium w-full md:w-auto'
                >
                  {t('Verify_AddWork')}
                </button>
              </div>
            </div>
          </div>

          <div className='w-full px-4 md:px-0'>
            {activeTab === 'add-work' && <AddWork isProgress />}
            {activeTab === 'maintenance' && <AddNewService isProgress />}
            {activeTab === 'repairing' && <Maintainence isProgress />}
            {activeTab === 'warranty' && <Waranity isProgress />}
          </div>

          <Modal
            isOpen={markVerify}
            onRequestClose={() => setMarkVerify(false)}
            className='fixed inset-0 flex items-center justify-center z-50 mx-4'
            overlayClassName='fixed inset-0 bg-black bg-opacity-50'
          >
            <div className='bg-gradient rounded-lg p-4 md:p-6 w-full max-w-md'>
              <h1 className='text-xl font-bold mb-4'>Confirmation</h1>
              <p className='mb-6 text-black'>
                Are you sure you want to mark this work as completed?
              </p>

              <div className='flex justify-end mt-6'>
                <button
                  className='px-4 py-2 text-white rounded hover:bg-blue-700 mr-2'
                  style={{ backgroundColor: '#009bb4' }}
                  onClick={() => {
                    handleVerifyAddWord();
                    setMarkVerify(false);
                    navigate('/dashboard');
                  }}
                >
                  {t('Confirms')}
                </button>
                <button
                  className='px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600'
                  onClick={() => {
                    setMarkVerify(false);
                  }}
                >
                  {t('Cancel')}
                </button>
              </div>
            </div>
          </Modal>
        </main>
      </div>
    </div>
  );
};

export default Progress;
