import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import info_icon from '../../images/info_icon.svg';
import { useAddNewServiceSurveyForm } from '../../Pages/AddNewService';
import Show from '../Common/show';
import { useTranslation } from 'react-i18next';

export default function SurveyForm({
  isReadOnly,
  handleUpdateComment,
  isProgress,
}) {
  const [boxes, setBoxes] = useState([]);
  const { t } = useTranslation();

  const { data, handleSetIsCheckedChange, requiredata } =
    useAddNewServiceSurveyForm();
  console.log('data', data);
  const storedItem = localStorage.getItem('selectedLanguage');

  const parsedItem = JSON.parse(storedItem);

  const handleCheckboxChange = (boxId, boxIndex, checkboxType) => {
    let newBoxes = [...boxes];

    handleSetIsCheckedChange(true, boxIndex);

    if (checkboxType === 'yes') {
      newBoxes[boxIndex].yes = true;
      newBoxes[boxIndex].no = false;
    } else if (checkboxType === 'no') {
      newBoxes[boxIndex].yes = false;
      newBoxes[boxIndex].no = true;
      newBoxes[boxIndex].executed = false;
    } else if (checkboxType === 'executed') {
      newBoxes[boxIndex].executed = true;
      newBoxes[boxIndex].pointerEvents = 'none';
      newBoxes[boxIndex].backgroundColor = '#181818';
      if (boxIndex < boxes.length - 1) {
        newBoxes[boxIndex + 1].pointerEvents = 'unset';
        newBoxes[boxIndex + 1].backgroundColor = '#2B2A2A';
      }
    }

    setBoxes(newBoxes);
  };

  const [open, setOpen] = useState(false);
  const [openvideo, setOpenvideo] = useState(false);

  const [modalImageUrls, setModalImageUrls] = useState([]);
  const [modalUrl, setModalUrl] = useState(null);

  const handleVideoClick = (url) => {
    setOpenvideo(true);

    setModalUrl(url);
  };

  const closeVideoModal = () => {
    setModalUrl(null);
    setOpenvideo(false);
  };
  const onOpenModal = (imageUrls) => {
    setOpen(true);

    setModalImageUrls(imageUrls);
  };
  const onCloseModal = () => setOpen(false);

  const processImageUrl = (url) => {
    const processedUrl = url.includes('service_images/')
      ? url
      : `service_images/${url}`;
    return `https://cfmoto-media.s3.amazonaws.com/${processedUrl}`;
  };
  useEffect(() => {
    var dataRequire = {};
    requiredata.map((item) => {
      dataRequire[item.name.trim()] = item;
    });

    if (Array.isArray(data)) {
      const formattedData = data.map((el, index) => ({
        yes:
          dataRequire[el.Maintainencepoint_name.trim()] &&
          dataRequire[el.Maintainencepoint_name.trim()].is_active === true
            ? true
            : false,
        no:
          dataRequire[el.Maintainencepoint_name.trim()] &&
          dataRequire[el.Maintainencepoint_name.trim()].is_active === false
            ? true
            : false,
        // Keep all cards active initially
        pointerEvents: 'unset',
        backgroundColor: '#2B2A2A',
        openTooltip: false,
        indexData: dataRequire[el.Maintainencepoint_name.trim()] ? true : false,
        ...el,
        ...dataRequire[el.Maintainencepoint_name.trim()],
      }));

      for (let i = 0; i < formattedData.length; i++) {
        if (!formattedData[i].indexData) {
          formattedData[i].backgroundColor = '#2B2A2A';
          formattedData[i].pointerEvents = 'unset';
          break;
        }
      }

      setBoxes(formattedData);
    }
  }, [data]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isXsmScreen = windowWidth <= 640;
  console.log('here boxes', boxes);
  return (
    <>
      <Show IF={!isReadOnly && boxes?.length}>
        <button
          className={`
            w-full 
            max-w-[80px]
            md:max-w-[100px]
            ${
              boxes.every((item) => item?.yes) || isReadOnly
                ? 'bg-gray-200'
                : 'bg-gradient'
            }
            rounded-[5px] 
            text-sm 
            md:text-base 
            lg:text-lg 
            font-supremeMedium
            flex
            items-center
            justify-center
            mb-5
            disabled:text-black-100
            disabled:cursor-not-allowed
          `}
          style={{ height: '30px', maxWidth: '100px' }} // Custom height and width
          onClick={() => {
            setBoxes(boxes.map((item) => ({ ...item, yes: true })));
            boxes.forEach((_, index) => {
              handleSetIsCheckedChange(true, index);
            });
          }}
          disabled={boxes.every((item) => item?.yes) || isReadOnly}
        >
          Check All
        </button>
      </Show>
      <div className='space-y-6'>
        {boxes?.map((box, index) => (
          <Card
            box={box}
            index={index}
            key={index}
            label={parsedItem.label}
            handleCheckboxChange={handleCheckboxChange}
            onOpenModal={onOpenModal}
            handleVideoClick={handleVideoClick}
            setBoxes={setBoxes}
            isReadOnly={isReadOnly}
            handleUpdateComment={handleUpdateComment}
            isProgress={isProgress}
          />
        ))}
      </div>

      {/* MODAL */}
      <div>
        {/* // Inside your modal component */}
        <Modal
          open={open}
          onClose={onCloseModal}
          classNames={{ modal: 'custom-modal' }}
          style={{
            height: 'auto',
            width: isXsmScreen ? '50% !important' : '100% !important', // Apply width with !important
            maxWidth: '90%', // Optional: Limit maximum width for larger screens
          }}
        >
          <button className='closes-icon' onClick={closeVideoModal}></button>

          {modalImageUrls.length === 1 ? (
            // Render single image if there's only one image
            <div className='transparent-background'>
              <img
                className='relative z-10 cursor-pointer'
                src={processImageUrl(modalImageUrls[0])}
                alt={`Image 0`}
                style={{ width: '100%', height: 'auto' }}
              />
            </div>
          ) : (
            // Render slider if there are multiple images

            <div className='transparent-background'>
              <Carousel>
                {modalImageUrls.map((url, index) => (
                  <div key={index}>
                    <img
                      className='relative z-10 cursor-pointer'
                      src={processImageUrl(url)}
                      alt={`Image ${index}`}
                      style={{ height: 'auto', width: '100%' }} // Adjust the width and height as needed
                    />
                  </div>
                ))}
              </Carousel>
            </div>
          )}
        </Modal>
        {/* Modal for playing the video */}
      </div>
      <div>
        <Modal
          open={openvideo}
          onClose={closeVideoModal}
          classNames={{ modal: 'custom-modal' }}
        >
          <button className='close-icon' onClick={closeVideoModal}></button>

          <div className='youtube-player'>
            <ReactPlayer url={modalUrl} className='videomodel' />
          </div>
        </Modal>
      </div>
    </>
  );
}

const Card = (props) => {
  const {
    index,
    box,
    handleCheckboxChange,
    onOpenModal,
    handleVideoClick,
    setBoxes,
    label,
    isReadOnly,
    handleUpdateComment,

    isProgress,
  } = props;
  const {
    handleYesCheckboxToggle,
    startTimerController,
    endTimerController,
    textValue,
    handleTextChange,
    handleFillChange,
    fillvalue,
    valvalue,
    handleValvalueChange,
  } = useAddNewServiceSurveyForm();

  const handleOpenThenCloseTooltip = () => {
    handleCloseTooltip();
    handleOpenTooltip();
  };

  const handleOpenTooltip = () => {
    setBoxes((prevBoxes) => {
      const newBoxes = prevBoxes.map((box, idx) => ({
        ...box,
        openTooltip: idx === index ? true : false, // Set openTooltip to true for current tooltip, false for others
      }));
      return newBoxes;
    });
  };
  const handleCloseTooltip = () => {
    setBoxes((prevBoxes) => {
      const newBoxes = [...prevBoxes];
      newBoxes[index].openTooltip = false;
      return newBoxes;
    });
  };

  const handleYesToggle = () => {
    startTimerController(index);
    handleCheckboxChange(box.id, index, 'yes');
    handleYesCheckboxToggle(true, index);

    endTimerController(index);
  };

  const imageUrls = [
    box?.image_1,
    box?.image_2,
    box?.image_3,
    box?.image_4,
    box?.image_5,
  ].filter((url) => url);
  const videoUrls = [
    box?.video1,
    box?.video2,
    box?.video3,
    // Add more if you have more videos
  ].filter((url) => url);

  // This filter removes any undefined or null values
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isXsmScreen = windowWidth <= 648;
  const { t } = useTranslation();

  return (
    <>
      <div
        style={{
          pointerEvents: box?.pointerEvents ?? 'none',
          backgroundColor: box?.backgroundColor ?? '#181818',
        }}
        className=' bg-black-600 rounded-[10px] pt-[21px] md:pt-[19px] xl:pt-3 pl-8 md:pl-[42px] xl:pl-[22px] pb-5 md:pb-[25px] xl:pb-[34px] pr-4 md:pr-[49px] xl:pr-11'
      >
        {/* TITLE */}
        <div className='relative w-fit mb-5 md:mb-1 xl:mb-5'>
          <div className=''>
            <h4 className='text-f_18_l_23 text-white-100'>
              {label && label.includes('Estonian')
                ? box?.Maintainencepoint_name_Estonian || ''
                : label && label.includes('Finnish')
                ? box?.Maintainencepoint_name_Finnish || ''
                : label && label.includes('Latvian')
                ? box?.Maintainencepoint_name_Latvian || ''
                : label && label.includes('English')
                ? box?.Maintainencepoint_name || ''
                : ''}

              {(box?.instructions || '').length > 0 && (
                <img
                  src={info_icon}
                  className='inline ml-[9px]'
                  onMouseEnter={handleOpenThenCloseTooltip}
                  alt='info-icon'
                />
              )}
            </h4>
            {/* INFO ICON */}
          </div>

          {/* TOOLTIP CONTENT */}
          {/* <div className="relative top-[1.8rem] bg-white-500 w-auto h-auto md:w-[40rem] md:h-[30rem] rounded-[4px] p-4 z-1 xsm:w-full" style={{ display: box.openTooltip ? "block" : "none", top: "calc(100% + 0.2rem)", left: "calc(50% + 5px)", transform: "translateX(-50%)" */}
          <div
            className='relative top-[1.8rem] bg-white-500 w-auto h-auto md:w-[40rem] md:h-[30rem] rounded-[4px] p-4 z-1 xsm:w-auto'
            style={{
              display: box.openTooltip ? 'block' : 'none',
              top: 'calc(100% + 0.2rem)',
              left: isXsmScreen ? 'calc(33% + 5px)' : 'calc(50% + 5px)',
              // Set left position to 5px from the left edge
              transform: 'translateX(-50%)', // Center horizontally using transform
            }}
            onMouseEnter={handleOpenThenCloseTooltip}
          >
            <div className='flex justify-end'>
              <button
                className='absolute top-0 right-0 p-2'
                onClick={handleCloseTooltip}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6 close-icon text-black'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={2}
                    d='M6 18L18 6M6 6l12 12'
                  />
                </svg>
              </button>
            </div>

            {/* TEXT */}
            <div className='mb-7 mt-5'>
              <h3
                className='text-f_20_l_25 leading-6 text-black-500'
                style={{ whiteSpace: 'pre-line' }}
              >
                {label && label.includes('Estonian')
                  ? box?.instructions_Estonian || ''
                  : label && label.includes('Finnish')
                  ? box?.instructions_Finnish || ''
                  : label && label.includes('Latvian')
                  ? box?.instructions_Latvian || ''
                  : label && label.includes('English')
                  ? box?.instructions || ''
                  : ''}
              </h3>
            </div>

            {/* IMAGES */}
            {imageUrls.length > 0 && (
              <h1
                className='text-black text-center mt-2'
                style={{ color: 'black', fontWeight: 'bold' }}
              >
                Images
              </h1>
            )}
            <div className='grid grid-cols-1 md:grid-cols-4 gap-10 py-5'>
              {imageUrls.map((url, index) => {
                const processedUrl = url.includes('service_images/')
                  ? url
                  : `service_images/${url}`;
                const fullUrl = `https://cfmoto-media.s3.amazonaws.com/${processedUrl}`;
                const handleClick = () => {
                  onOpenModal(imageUrls);
                };

                return (
                  <img
                    key={index}
                    className='relative z-10 cursor-pointer'
                    src={fullUrl}
                    alt={`Image ${index}`}
                    onClick={handleClick}
                  />
                );
              })}
            </div>

            {/* VIDEOS */}
            {videoUrls.length > 0 && (
              <h1
                className='text-black text-center mt-2'
                style={{ color: 'black', fontWeight: 'bold' }}
              >
                Videos
              </h1>
            )}
            <div className='grid grid-cols-1 md:grid-cols-4 gap-10 py-5'>
              {videoUrls.map((url, index) => {
                const videoId = url.split('/').pop().split('?')[0];

                return (
                  <div
                    key={index}
                    className='cursor-pointer'
                    onClick={() => {
                      handleVideoClick(url);
                    }}
                  >
                    <div className='relative'>
                      <img
                        src={`https://img.youtube.com/vi/${videoId}/0.jpg`}
                        alt={`Video ${index}`}
                        className='w-full h-auto'
                      />
                      {/* Play icon overlay */}
                      <div className='absolute inset-0 flex items-center justify-center opacity-75 bg-black'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          className='h-16 w-16 text-white'
                          fill='currentColor'
                          viewBox='0 0 24 24'
                        >
                          <path d='M8 5v14l11-7z' />
                        </svg>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className='flex flex-col md:flex-row items-stretch md:items-center justify-between pr-4 md:pr-0 gap-y-4 md:gap-y-0'>
          {/* LEFT */}
          <div className='flex flex-col xl:flex-row items-start xl:items-center gap-y-4 md:gap-y-3.5 xl:gap-y-0 xl:gap-x-2 w-full md:w-4/5 xl:w-[87%]'>
            {/* LEFT */}
            <div className='flex flex-col md:flex-row items-start md:items-center gap-y-4 md:gap-y-0 md:gap-x-6'>
              {/* YES AND NO */}
              <div className='flex gap-x-6'>
                {/* YES CHECKBOX */}
                <div className='flex items-center'>
                  <input
                    disabled={isReadOnly}
                    id={`yes-${box.id}`}
                    checked={isReadOnly ? box.is_active : box.yes}
                    // checked={foundrecord?.is_active||box.yes}
                    type='checkbox'
                    value=''
                    className='w-[19px] h-[19px] text-transparent bg-transparent border-[0.5px] border-black-700 rounded-sm checked:border-black-700 focus:ring-transparent focus:ring-offset-0 focus:ring-2'
                    onChange={() => handleYesToggle()}
                  />
                </div>
              </div>

              <div className='flex gap-x-7'>
                {box.no && box.fill_active && (
                  <input
                    className='w-[83px] h-[33px] xl:h-[39px] rounded-sm bg-black-500 border-[0.5px] border-black-700 py-1.5 px-[9px]  text-f_14_l_22 xl:text-f_18_l_28 placeholder:text-white-400'
                    placeholder='Fill'
                    type='text'
                    id={`input1-${box.id}`}
                    value={fillvalue[index] || ''}
                    onChange={(e) => handleFillChange(e, index)}
                    disabled={isReadOnly}
                  />
                )}
                {/* VALUE INPUT */}
                {box.no && box.value_active && (
                  <input
                    className='w-[83px] xl:w-[95px] h-[33px] xl:h-[39px] rounded-sm bg-black-500 border-[0.5px] border-black-700 py-1.5 px-[9px]  text-f_14_l_22 xl:text-f_18_l_28 placeholder:text-white-400'
                    placeholder='Value'
                    type='text'
                    id={`input2-${box.id}`}
                    value={valvalue[index] || ''}
                    onChange={(e) => handleValvalueChange(e, index)}
                    disabled={isReadOnly}
                  />
                )}
              </div>
            </div>

            <div className='w-full'>
              {/* COMMENT INPUT */}
              <input
                className='w-full h-[33px] xl:h-[39px] rounded-sm bg-black-500 border-[0.5px] border-black-700 py-1.5 px-[9px]  text-f_14_l_22 xl:text-f_18_l_28 placeholder:text-white-400'
                placeholder='Comment'
                type='text'
                id={`input3-${box?.id}`}
                value={
                  !isReadOnly ? box?.comment : textValue[index] || box?.comment
                }
                onChange={(e) => handleTextChange(e, index)}
                // disabled={isReadOnly || !isProgress}
              />
            </div>
            {/* <Show IF={!isReadOnly}> */}
            <div className='flex'>
              {isProgress && (
                <button
                  className='p-2 bg-gradient rounded-[5px] text-f_16_l_20 font-supremeMedium'
                  onClick={() => {
                    handleUpdateComment({
                      boxId: box.Point_id,
                      comment: textValue[index],
                    });
                  }}
                >
                  {t('Update')}
                </button>
              )}
            </div>
            {/* </Show> */}

            {/* )} */}
          </div>
        </div>
      </div>
    </>
  );
};
