import axios from 'axios';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import MultiSelectDropdown from '../components/Common/dropdown';
import Header from '../components/Common/Header';
import Sidebar from '../components/Common/Sidebar';
import { loaderActions } from '../state/actions';
import { AddValueAndLabel } from '../utils/functions';

function History_button() {
  const storedItem = localStorage.getItem('selectedLanguage');
  const dispatch = useDispatch();

  const parsedItem = JSON.parse(storedItem);

  const label = parsedItem.label;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [checkStatus, setCheckStatus] = useState(false);
  const [checkboxState, setCheckboxState] = useState({
    is_warranty: false,
    is_repair: false,
    is_maintenance: false,
  });

  React.useEffect(() => {
    if (!localStorage.getItem('vinnData')) {
      navigate('/vinn');
    } else {
      getApiData();
    }
  }, []);
  const data = localStorage.getItem('vinnData');
  const parsed_data = JSON.parse(data);
  const [showModal, setShowModal] = useState(false);
  const [selectedHistory, setSelectedHistory] = useState(null);

  const handleCheckboxChange = (type) => {
    setCheckboxState((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  function formatTimestamp(timestamp) {
    const dateObj = new Date(timestamp);

    const dateStr = dateObj.toLocaleDateString('en-US');
    const parts = dateStr.split('/');
    const formattedDate = `${parts[1]}/${parts[0]}/${parts[2]}`;
    const timeStr = dateObj.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    });

    return `${formattedDate} ${timeStr}`;
  }

  const [hasWarrantyRegistration, setHasWarrantyRegistration] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const db_data = parsed_data?.data;
  const [db_data_history, setDb_Data] = useState(null);

  const getApiData = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/products/${db_data?.vin_code}/history/`
    );

    if (res?.data?.length) {
      const reversedData = [...res.data].reverse();
      setDb_Data(reversedData);
    }
  };
  React.useEffect(() => {
    getApiData();
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/warranty-registration/${db_data?.id}/`
        );
        const data = await response.json();
        setHasWarrantyRegistration(data.length > 0);
        setIsLoaded(true); // Set loading state to true when data fetching is complete
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error here
      }
    };

    setIsLoaded(false);

    fetchData();
  }, [db_data?.id]);

  const handleClick = (item) => {
    if (item?.description.includes('Assembling made')) {
      navigate('/vehicleData/displayassembling');
    } else if (item?.description.includes('Warranty registration')) {
      navigate('/vehicleData/displaywarrantyregister');
    } else if (item?.description.includes('Work created')) {
      navigate(
        `/service/Addwork?readOnly=1&workId=${
          item?.work_id ? item?.work_id : ''
        }`
      );
    } else if (item?.description.includes('Maintenance made')) {
      navigate(
        `/service/AddNewService?readOnly=1&workId=${
          item?.work_id ? item?.work_id : ''
        }`
      );
    } else if (item?.description.includes('Repairing made')) {
      navigate(
        `/service/maintainence?readOnly=1&workId=${
          item?.work_id ? item?.work_id : ''
        }`
      );
    } else if (item?.description.includes('Warranty made')) {
      navigate(
        `/service/Waranty?readOnly=1&workId=${
          item?.work_id ? item?.work_id : ''
        }`
      );
    }
  };

  const handlePrintHistory = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedHistory(null); // Reset selected history when modal is closed
  };

  const handlePrintSelected = async () => {
    try {
      dispatch(loaderActions.showLoader());

      const queryParams = new URLSearchParams();

      if (selectedHistory?.work_id) {
        queryParams.append('work_id', selectedHistory.work_id);
      }

      if (checkboxState.is_warranty) {
        queryParams.append('is_warranty', 'true');
      }
      if (checkboxState.is_repair) {
        queryParams.append('is_repair', 'true');
      }
      if (checkboxState.is_maintenance) {
        queryParams.append('is_maintenance', 'true');
      }

      const apiUrl = `${
        process.env.REACT_APP_API_URL
      }/api/addwork/report/generate-report/?${queryParams.toString()}`;

      const response = await axios.get(apiUrl);

      if (response.status === 200 && response.data?.pdf_url) {
        const pdfUrl = response.data.pdf_url;

        // Create an anchor element
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.target = '_blank';
        link.rel = 'noopener noreferrer'; // Security best practice

        // Append to document, click, and remove
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      toast.error('Error generating report');
    } finally {
      dispatch(loaderActions.hideLoader());
      setShowModal(false);
    }
  };

  const formatCreatedAt = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `Created at: ${day}/${month}/${year} at ${hours}:${minutes}`;
  };

  const historyOptions = AddValueAndLabel(
    db_data_history
      ?.filter((item) => item?.description.includes('Work created'))
      .map((item) => ({
        ...item,
        description: `${item.description} / ${formatCreatedAt(item.timestamp)}`,
      })),
    'id',
    'description'
  );

  return (
    <div className='flex flex-col sm:flex-row'>
      <Sidebar
        openSidebar={openSidebar}
        setOpenSidebar={setOpenSidebar}
        setCheckStatus={setCheckStatus}
      />

      <Header setOpenSidebar={setOpenSidebar} />

      <main className='flex-1 md:ml-[16.25rem] xl:ml-[21.125rem] pt-[62px] md:pt-[6.5625rem] w-full'>
        {/* VEHICLE DATA */}
        <div className='p-4 sm:p-6'>
          <div className='flex flex-col sm:flex-row justify-between items-center'>
            <h1 className='text-lg sm:text-xl md:text-2xl font-bold mb-4 sm:mb-0'>
              {t('History')}
            </h1>
            <button
              className='px-4 py-2 bg-gradient rounded-[10px] text-sm sm:text-base font-medium'
              onClick={handlePrintHistory}
            >
              Print History
            </button>
          </div>

          <ul className='mt-4'>
            {db_data_history?.map((item, index) => (
              <li
                key={index}
                className={`flex flex-col sm:flex-row items-start sm:items-center p-3 sm:p-4 ${
                  index % 2 === 0 ? 'bg-black-400' : 'bg-black-500'
                } cursor-pointer`}
                onClick={() => handleClick(item)}
              >
                <div className='text-sm sm:text-base mb-2 sm:mb-0 sm:min-w-[50px]'>
                  {db_data_history.length - index}
                </div>
                <div className='flex flex-col sm:flex-row sm:ml-4'>
                  <div className='text-sm sm:text-base mb-2 sm:mb-0 sm:min-w-[180px]'>
                    {formatTimestamp(item.timestamp)}
                  </div>
                  <div className='text-sm sm:text-base sm:ml-4'>
                    {label === 'Estonian'
                      ? item.estonian_description
                      : label === 'Latvian'
                      ? item.latvian_description
                      : label === 'Finnish'
                      ? item.finnish_description
                      : item.description}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* Modal for selecting history */}
        <Modal
          isOpen={showModal}
          onRequestClose={handleModalClose}
          className='w-full max-w-md mx-auto z-50 custom-modal px-4 sm:px-8 py-6 sm:py-8 rounded-[8px] relative'
          overlayClassName='fixed inset-0 flex bg-black-800 bg-opacity-80 items-center justify-center p-4'
        >
          {/* Close Button */}
          <button onClick={handleModalClose} className='absolute top-2 right-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              stroke='currentColor'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              className='lucide lucide-x'
            >
              <path d='M18 6 6 18' />
              <path d='m6 6 12 12' />
            </svg>
          </button>

          <div className='my-4'>
            <h2 className='text-base sm:text-lg font-bold'>
              Select History Entries
            </h2>
          </div>

          {/* MultiSelectDropdown */}
          <MultiSelectDropdown
            options={historyOptions || []}
            value={selectedHistory}
            onChange={(value) => setSelectedHistory(value)}
            name='history'
            placeholder='Select History to print'
            allowTextWrap={true}
            customStyles={{
              menuButton: 'custom-class',
              listItem: 'another-custom-class',
            }}
          />

          {/* Checkboxes */}
          <div className='space-y-4 mt-6'>
            <div className='flex flex-col sm:flex-row gap-4 sm:gap-6'>
              {checkStatus?.is_maintenance && (
                <label className='flex items-center cursor-pointer'>
                  <input
                    type='checkbox'
                    checked={checkboxState.is_maintenance}
                    onChange={() => handleCheckboxChange('is_maintenance')}
                    className='w-5 h-5 sm:w-6 sm:h-6 rounded-md border-2 border-gray-400 checked:bg-blue-500 checked:border-blue-500 focus:ring-2 focus:ring-blue-500'
                  />
                  <span className='ml-2 sm:ml-3 text-sm sm:text-base'>
                    Maintenance
                  </span>
                </label>
              )}

              {checkStatus?.is_repair && (
                <label className='flex items-center cursor-pointer'>
                  <input
                    type='checkbox'
                    checked={checkboxState.is_repair}
                    onChange={() => handleCheckboxChange('is_repair')}
                    className='w-5 h-5 sm:w-6 sm:h-6 rounded-md border-2 border-gray-400 checked:bg-blue-500 checked:border-blue-500 focus:ring-2 focus:ring-blue-500'
                  />
                  <span className='ml-2 sm:ml-3 text-sm sm:text-base'>
                    Repair
                  </span>
                </label>
              )}

              {checkStatus?.is_warranty && (
                <label className='flex items-center cursor-pointer'>
                  <input
                    type='checkbox'
                    checked={checkboxState.is_warranty}
                    onChange={() => handleCheckboxChange('is_warranty')}
                    className='w-5 h-5 sm:w-6 sm:h-6 rounded-md border-2 border-gray-400 checked:bg-blue-500 checked:border-blue-500 focus:ring-2 focus:ring-blue-500'
                  />
                  <span className='ml-2 sm:ml-3 text-sm sm:text-base'>
                    Warranty
                  </span>
                </label>
              )}
            </div>
          </div>

          {/* Buttons */}
          <div className='mt-8 flex flex-col sm:flex-row gap-4'>
            <button
              onClick={handlePrintSelected}
              className='bg-gradient text-white p-2 rounded text-sm sm:text-base'
            >
              Print Selected
            </button>
            <button
              onClick={handleModalClose}
              className='bg-gray-200 text-black-100 p-2 rounded text-sm sm:text-base'
            >
              Close
            </button>
          </div>
        </Modal>
      </main>
    </div>
  );
}

export default History_button;
